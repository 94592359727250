.main-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .background-video-wrapper {
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    top: 0;
    right: 0;

    &.bring-front {
      position: absolute;
      z-index: 10;

      .preview-image-wrapper {
        display: none;
      }
    }

    .preview-image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      opacity: 1;
      transition: opacity 1s;

      &.hide {
        opacity: 0;
      }
    }
  }

  .panorama-viewer {
    width: 100%;
    height: 100%;
  }

  .content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .top-overlay-color {
      background: linear-gradient(180deg, rgba(20, 7, 37, 1) 30%, transparent);
      height: 30%;
      width: 100%;

      &.bring-front {
        position: absolute;
        z-index: 20;
      }
    }

    .bottom-overlay-color {
      background: linear-gradient(0deg, #140725 80%, transparent);
      height: 400px;
      width: 100%;
      position: absolute;
      bottom: 0;
      padding-top: 60px;
      opacity: 0.8;
      box-sizing: border-box;

      &.bring-front {
        z-index: 20;
      }

      .desc-wrapper {
        color: #fff;

        .desc-inner-wrapper {
          display: flex;
          justify-content: space-around;

          .text-wrapper {
            width: 30%;
            text-align: justify;

            .title {
              font-size: 20px;
            }

            p {
              line-height: 1.5em;
            }

            &.right-wrapper {
              p {
                line-height: 1.3em;
              }
            }
          }


        }
      }
    }

    .content-inner-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 1s;
      opacity: 1;

      &.hide {
        opacity: 0;
      }

      .title-wrapper {
        color: #fff;
        letter-spacing: 3px;
        text-align: center;
        margin-top: 5%;

        &.bring-front {
          z-index: 20;
          position: absolute;
          width: 100%;
        }

        .tc {
          font-size: 40px;
          font-family: CHei3HK;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .en {
          font-size: 14px;
          font-family: "Copperplate Gothic";
          margin-bottom: 10px;
        }

        .ledby {}

        .vr-video-info {
          display: none;
        }
      }


      .center-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        height: 40%;
        display: flex;
        align-items: center;

        .btn-play-wrapper {
          flex: 1 1 60%;
          display: flex;
          justify-content: center;

          .btn-play {
            width: 100px;
            height: 100px;
            transition: 1s opacity;
            opacity: 1;

            &.hide {
              opacity: 0;
            }
          }
        }



        .desc-wrapper {
          display: flex;
          justify-content: flex-end;
          flex: 1 0 40%;

          .desc-inner-wrapper {
            background-color: #fff;
            padding: 20px 5% 20px 40px;
            text-align: justify;
            // max-width: 45%;
            box-sizing: border-box;

            .title {
              font-size: 25px
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {

    .background-video-wrapper {
      width: 100%;

      &.bring-front {

        .preview-image-wrapper {}
      }

      .preview-image-wrapper {

        &.hide {}
      }
    }

    .content-wrapper {

      &.video-playing {
        .top-overlay-color {

          &.bring-front {}
        }

        .bottom-overlay-color {
          z-index: unset;

          &.bring-front {}

          .desc-wrapper {

            .desc-inner-wrapper {

              .text-wrapper {

                .title {}

                p {}

                &.right-wrapper {
                  p {}
                }
              }


            }
          }
        }

        .content-inner-wrapper {

          &.hide {}

          .title-wrapper {

            &.bring-front {}

            .tc {
              font-size: 30px;
            }

            .en {
              font-size: 12px;
            }

            .ledby {
              font-size: 12px;
            }
          }


          .center-wrapper {

            .btn-play-wrapper {

              .btn-play {

                &.hide {}
              }
            }



            .desc-wrapper {

              .desc-inner-wrapper {

                .title {}
              }
            }
          }
        }
      }

      .top-overlay-color {

        &.bring-front {}
      }

      .bottom-overlay-color {
        height: 33vh;
        padding-top: 10px;
        z-index: 10;
        overflow-y: auto;

        &.bring-front {}

        .desc-wrapper {

          .desc-inner-wrapper {
            flex-wrap: wrap;
            padding: 10px 20px 30px 20px;
            font-size: 12px;

            .text-wrapper {
              width: 100%;

              .title {}

              p {}

              &.right-wrapper {
                p {}
              }
            }


          }
        }
      }

      .content-inner-wrapper {

        &.hide {}

        .title-wrapper {

          &.bring-front {}

          .tc {
            font-size: 30px;
          }

          .en { 
            font-size: 12px;
          }

          .ledby {
            font-size: 12px;
          }

          .vr-video-info {
            display: block;
            margin-top: 10px;
            font-size: 10px;
          }
        }


        .center-wrapper {

          .btn-play-wrapper {

            .btn-play {

              &.hide {}
            }
          }



          .desc-wrapper {

            .desc-inner-wrapper {

              .title {}
            }
          }
        }
      }
    }
  }
}