.main-wrapper {
  width: 100vw;
  height: 100%;
  background-color: #19031b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 250px 0;
  box-sizing: border-box;

  .btn-back {
    position: fixed;
    top: 100px;
    right: 40px;
    width: 50px;
    height: 50px;
    z-index: 110;
  }

  img {
    width: 80%;
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    padding: 180px 0;

    .btn-back {
      top: 120px;
      right: 10px;
    }
  }
}