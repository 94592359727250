.main-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;

  .panorama-viewer {
    width: 100%;
    height: 100%;
  }

  .progress-bar {
    width: 0;
    height: 5px;
    position: fixed;
    top: 0;
    background: #fff;
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;

    &.finish {
      opacity: 0;
    }
  }
}