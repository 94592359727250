:root {
  --max-frame-width: 1100px;
  --main-wrapper-min-height: calc(100vh - 300px);
  --theme-color: #422571;
  --theme-text-color: #8065ac;
}

@font-face {
  font-family: "CHei3HK";
  src: url(./assets/fonts/CHei3HK-Bold.otf) format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Copperplate Gothic";
  src: url(./assets/fonts/COPRGTL.ttf) format("truetype");
}

@font-face {
  font-family: "Copperplate Gothic";
  src: url(./assets/fonts/COPRGTB.ttf) format("truetype");
  font-weight: bold;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Arial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

*:focus {
  outline: none;
}

button {
  border: 0;
  font-size: 1em;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.btn {
  cursor: pointer;
}

a {
  color: unset;
  text-decoration: none;
}

a:hover {
  color: unset;
  text-decoration: none;
  cursor: pointer;
}