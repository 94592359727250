.main-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .background-wrapper {
    width: 100%;
    height: 100%;
    background-color: #19031b;
  }

  .scroll-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow-y: scroll;

    .scroll-content {
      width: 100%;
      height: 500vh;
      font-size: 100px
    }
  }

  .main-title-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 60%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    top: 0;
    bottom: 0;

    .bblogo-wrapper {
      width: 200px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-self: flex-end;
      margin-bottom: 20px;
    }

    .title-wrapper {
      width: 100%;

      .instruction-wrapper {
        color: #fff;
        text-align: center;
        font-size: 50px;
        margin-top: 20px;
        letter-spacing: 5px;
        opacity: 0
      }
    }
  }

  .p1-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .p2-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .p3-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .p4-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .p5-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .p6-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .p7-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .p8-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .p9-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .p10-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .p11-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .scroll-icon-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10%;
    margin: auto;
    width: 150px;
    height: 150px;
  }

  .hkadc-logo-wrapper {
    position: absolute;
    right: 10%;
    bottom: 2%;
    margin: auto;
    width: 250px;
    height: 100px;
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {

    .background-wrapper {}

    .scroll-wrapper {

      .scroll-content {}
    }

    .p1-wrapper {
      left: unset;
      right: 0;
    }

    .p2-wrapper {}

    .p3-wrapper {
      top: 0;
      left: unset;
      right: 0;
    }

    .p4-wrapper {}

    .p5-wrapper {
      bottom: unset;
      top: 0;
    }

    .p6-wrapper {
      bottom: 0;
      left: 0;
      right: unset;
      top: unset;
    }

    .p7-wrapper {
      top: 0;
      left: unset;
      right: 0;
    }

    .p8-wrapper {
      top: unset;
      bottom: 0;
      right: 0;
    }

    .p9-wrapper {
      right: unset;
      left: 0;
      bottom: 0;
    }

    .p10-wrapper {
      bottom: 0;
      left: 0;
    }

    .p11-wrapper {
      bottom: 0;
      left: 0;
    }

    .main-title-wrapper {
      width: 100%;
      top: -10%;

      .bblogo-wrapper {
        height: 80px;
      }

      .title-wrapper {
        padding: 0 10px;
        box-sizing: border-box;

        .instruction-wrapper {
          font-size: 20px;
        }
      }
    }

    .scroll-icon-wrapper {
      width: 50px;
      height: 50px;
      bottom: 30%;
    }

    .hkadc-logo-wrapper {
      right: 0;
      left: 0;
      width: 50%;
      height: 80px;
      display: flex;
      align-items: center;
      bottom: 5%;
    }
  }
}