.main-wrapper {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  .content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .top-overlay-color {
      background: linear-gradient(180deg, rgba(20, 7, 37, 1) 30%, transparent);
      height: 30%;
      width: 100%;
    }

    .bottom-overlay-color {
      background: linear-gradient(0deg, rgba(20, 7, 37, 1) 30%, transparent);
      height: 30%;
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    .content-inner-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-bottom: 100px;

      .title-wrapper {
        color: #fff;
        letter-spacing: 3px;
        flex: 1 0 100%;

        .tc {
          font-size: 60px;
          font-family: CHei3HK;
          font-weight: bold
        }

        .en {
          font-size: 24px;
          font-family: "Copperplate Gothic"
        }
      }

      .desc-wrapper {
        background-color: rgba(255, 255, 255, .85);
        padding: 20px 40px;
        text-align: justify;
        width: 60%;
        max-width: 900px;
      }
    }

  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    height: calc(100vh - 30px);

    .content-wrapper {

      .top-overlay-color {}

      .bottom-overlay-color {}

      .content-inner-wrapper {
        width: 100%;
        margin-bottom: 0;
        justify-content: center;


        .title-wrapper {
          flex: 0 0 80%;
          text-align: center;
          margin-bottom: 30px;

          .tc {
            font-size: 40px;
          }

          .en {
            font-size: 18px;
          }
        }

        .desc-wrapper {
          width: 100%;
          max-width: unset;
          font-size: 12px;
          padding: 10px 20px;
          height: 20vh;
          overflow-y: auto;
        }
      }

    }
  }
}