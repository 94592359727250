.main-wrapper {

  .leftbar {
    position: fixed;
    width: 30px;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: var(--theme-color);
    z-index: 20;
  }

  .copyright {
    position: fixed;
    height: 30px;
    background-color: var(--theme-color);
    bottom: 0;
    left: 0;
    right: 0;
    color: var(--theme-text-color);
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    font-size: 14px;
    z-index: 20;
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {

    .leftbar {
      display: none;
    }

    .copyright {
      font-size: 10px;
    }
  }
}