.main-wrapper {
  width: 100vw;
  height: 100vh;

  .btn-back {
    position: fixed;
    top: 100px;
    right: 40px;
    width: 50px;
    height: 50px;
    z-index: 110;
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
  
    .btn-back {
      top: 120px;
      right: 10px;
    }
  }
}