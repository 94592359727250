.main-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(66, 37, 113, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner-wrapper {
    width: 50%;
    height: 80%;
    position: relative;

    .btn-back-wrapper {
      width: 60px;
      height: 60px;
      position: absolute;
      top: -20px;
      right: -50px;
    }

    .title-wrapper {
      color: #fff;
      position: absolute;
      top: -30px;
      left: 0;
      letter-spacing: 3px;
      z-index: 1;
      background-color: #6335af;
      padding: 10px;

      // &:before {
      //   content: "";
      //   position: absolute;
      //   width: 100%;
      //   height: 80%;
      //   background-color: #6335af;
      //   bottom: 0;
      //   left: 0;
      //   z-index: -1;
      // }

      .tc {
        font-size: 40px;
        font-family: CHei3HK;
        font-weight: bold
      }

      .en {
        font-size: 14px;
        font-family: "Copperplate Gothic"
      }
    }

    .content-wrapper {
      display: flex;
      height: 100%;


      .content-inner-wrapper {
        height: 100%;

        .image-wrapper {
          margin: 0 40px;
          display: flex;
          max-height: 60%;
          justify-content: center;
        }

        .description-wrapper {
          background-color: #fff;
          padding: 20px 40px;
          text-align: justify;
          line-height: 1.4em;
          max-height: 40%;
          overflow: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .main-wrapper {

    .inner-wrapper {
      width: 90%;
      height: 75%;

      .btn-back-wrapper {
        top: unset;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      .title-wrapper {
        top: -50px;

        .tc {
          font-size: 30px;
        }

        .en {}
      }

      .content-wrapper {
        flex-wrap: wrap;


        .content-inner-wrapper {
          margin-top: 10px;

          .image-wrapper {
            margin: 0 20px;
          }

          .description-wrapper {
            font-size: 12px;
            padding: 40px 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {

    .inner-wrapper {
      width: 90%;

      .btn-back-wrapper {
        width: 50px;
        height: 50px;
      }

      .title-wrapper {
        top: -50px;

        .tc {
          font-size: 25px;
        }

        .en {}
      }

      .content-wrapper {
        flex-wrap: wrap;

        .content-inner-wrapper {
          margin-top: 10px;

          .image-wrapper {
            margin: 0 10px;
          }

          .description-wrapper {
            font-size: 12px;
            padding: 20px 10px;
          }
        }
      }
    }
  }
}