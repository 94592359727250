.main-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;

  .background-video-wrapper {}

  .scroll-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow-y: scroll;

    .scroll-content {
      width: 100%;
    }
  }

  .content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.stickly {
      position: fixed;
    }

    .top-overlay-color {
      background: linear-gradient(180deg, rgba(20, 7, 37, 1) 30%, transparent);
      height: 10%;
      width: 100%;
    }

    .bottom-overlay-color {
      background: linear-gradient(0deg, rgba(20, 7, 37, 1) 30%, transparent);
      height: 10%;
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    .content-inner-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .title-wrapper {
        color: #fff;
        letter-spacing: 3px;
        text-align: center;
        margin-top: 5%;

        .tc {
          font-size: 16px;
          font-family: CHei3HK;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .en {
          font-size: 14px;
          font-family: "Copperplate Gothic";
          margin-bottom: 10px;
        }

        .ledby {}
      }


      .center-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .desc-wrapper {
          flex: 0 0 40%;
          color: white;

          .desc-inner-wrapper {
            text-align: justify;
            max-width: 900px;
            box-sizing: border-box;
            padding: 0 20% 0 0;


            .desc-title-wrapper {
              .tc {
                font-size: 40px;
                font-family: CHei3HK;
                font-weight: bold;
                margin-bottom: 10px;
              }

              .en {
                font-size: 14px;
                font-family: "Copperplate Gothic";
                margin-bottom: 10px;
              }

            }

            p {}
          }
        }
      }
    }
  }
}