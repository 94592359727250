.main-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 30px;
  z-index: 100;
  min-height: 100px;

  &.menu-open {
    transition: background-color .5s, min-height .5s .5s;
    min-height: 50vh;
    background-color: var(--theme-color);
    z-index: 120;
  }

  &.menu-close {
    transition: min-height .5s, background-color .5s .5s;
    min-height: 100px;
    background-color: unset;
  }

  .btn-menu-wrapper {
    background-image: url('../../assets/header/btn-menu.svg');
    width: 50px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 0 40px 0 auto;
  }

  .main-title-wrapper {
    margin: 10px auto 20px;
    background-image: url('../../assets/header/title.png');
    width: 40%;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .menu-wrapper {
    color: #fff;
    margin: 50px 0;
    text-align: center;
    font-size: 20px;
    transition: opacity .3s;
    opacity: 0;

    &.show {
      opacity: 1;
    }

    .menu-buttons-wrapper {

      .menu-button {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        position: relative;
        padding-bottom: 5px;

        &:after {
          content: "";
          border-bottom: 3px solid #fff;
          width: 150px;
          position: absolute;
          bottom: 0px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    margin: 0;
    padding-top: 10px;

    &.menu-open {}

    &.menu-close {}

    .btn-menu-wrapper {
      margin: 0 10px 0 auto;
    }

    .main-title-wrapper {
      flex: 0 0 70%;
      width: 90%;
      margin-top: 0;
    }

    .menu-wrapper {

      &.show {}

      .menu-buttons-wrapper {

        .menu-button {

          &:after {}

          &:last-child {}
        }
      }
    }
  }
}