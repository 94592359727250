.main-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 30px 0px 30px;
  background: linear-gradient(180deg, rgba(66, 37, 113, 0.5), transparent);

  .logo-wrapper {
    background-image: url('../../assets/vr/bb_logo.png');
    width: 110px;
    height: 70px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .icon-wrapper {
    position: fixed;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .icon-360 {
      background-image: url('../../assets/vr/360_icon.png');
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .instruction-text {
      color: #fff;
      text-align: center;
      flex: 0 0 100%;
    }
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper {
    justify-content: flex-start;
    padding: 0;
    padding-top: 10px;
    padding-right: 10px;
    margin-left: unset;

    .logo-wrapper {
      height: 40px;
      width: 30%;
      margin: 5px 0;
    }

    .icon-wrapper {
      bottom: 8%;

      .icon-360 {
        height: 40px;
        width: 40px;

      }

      .instruction-text {}
    }
  }
}